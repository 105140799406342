import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

export const FixedImage = ({ image, alt, imgStyle, objectPosition, objectFit, style }) => (
  <>
    {typeof image === 'object' && image && (
      <Img
        imgStyle={imgStyle}
        fadeIn={false}
        objectFit={objectFit}
        fixed={image.childImageSharp.fixed}
        objectPosition={objectPosition}
        draggable={false}
        alt={alt}
        style={style}
      />
    )}
    {typeof image === 'string' && <img src={image} style={imgStyle} alt={alt} />}
  </>
);

FixedImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string,
  imgStyle: PropTypes.object,
  objectPosition: PropTypes.string,
  objectFit: PropTypes.string,
  style: PropTypes.object,
};

FixedImage.defaultProps = {
  alt: '',
  imgStyle: {},
  objectPosition: '',
  objectFit: '',
  style: {},
};
