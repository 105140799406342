import styled from 'styled-components';

export default {
  AboutSection: styled.section``,
  Image: styled.div`
    width: 600px;
    height: 400px;
    padding: 0px 3px;
    flex-shrink: 0;
  `,
  Text: styled.div`
    white-space: nowrap;
  `,
};
