import styled, { css } from 'styled-components';
import { darken } from 'polished';

export default {
  FontIcon: styled.span`
    font-size: ${({ size }) => `${size}px`};
    color: ${({ color }) => color};
    transition: all 0.25s ease;

    ${({ spin }) =>
      spin &&
      css`
        -moz-animation: spin 2s infinite linear;
        -o-animation: spin 2s infinite linear;
        -webkit-animation: spin 2s infinite linear;
        animation: spin 2s infinite linear;
        display: inline-block;
      `}

    ${({ hoverable }) =>
      hoverable &&
      css`
        cursor: pointer;
        &:hover {
          color: ${({ color }) => darken(0.1, color)};
        }
      `}
  `,
};
