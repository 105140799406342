import React, { useRef } from 'react';

import { Nav, useResizeObserver } from 'src/modules/layout';
import S from './index.Header.Styled';

const Header = ({ menuItems, current }) => {
  const titleRef = useRef();
  const { height } = useResizeObserver(titleRef);

  return (
    <>
      <S.Title ref={titleRef}> Cia Landscapes + Colour</S.Title>
      <S.Address>Red Hill Act, 2603, Australia</S.Address>
      <S.Nav top={height} menuItems={menuItems} current={current} />
    </>
  );
};

Header.propTypes = {
  ...Nav.propTypes,
};

export default Header;
