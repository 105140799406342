import styled from 'styled-components';
import { Nav } from 'src/modules/layout';

export default {
  Title: styled.div`
    position: sticky;
    top: 0;
    z-index: 3;
    background: white;
  `,
  Address: styled.div``,
  Nav: styled(Nav)`
    top: ${({ top }) => `${top}px`};
    z-index: 3;
  `,
};
