import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import IndexPageTemplate from './index/index.Template';

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return <IndexPageTemplate homeData={frontmatter.home} aboutData={frontmatter.about} indexData={frontmatter.index} />;
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object.isRequired,
    }),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        address
        home {
          images {
            image {
              childImageSharp {
                fixed(height: 400) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          description
        }
        about {
          images {
            image {
              childImageSharp {
                fixed(height: 400) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          description
        }
        index {
          description
        }
      }
    }
  }
`;
