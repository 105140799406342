import React from 'react';
import PropTypes from 'prop-types';

import { convertMDToInnerHTML } from 'src/modules/text';
import { HorizontalScroller, TextScroller, useCenterIntersectionObserver } from 'src/modules/layout';
import { FixedImage } from 'src/modules/image';
import S from './index.HomeSection.Styled';

const HomeSection = ({ data, onCenterReached }) => {
  const { description, images } = data;
  useCenterIntersectionObserver(() => {
    onCenterReached();
  }, 'home');

  return (
    <S.HomeSection id="home">
      <HorizontalScroller imageContainer>
        {images.map((imageObj, index) =>
          !imageObj.image ? null : (
            /* eslint-disable-next-line react/no-array-index-key */
            <S.Image key={index}>
              <FixedImage image={imageObj.image} objectFit="cover" style={{ width: 600, height: 400 }} />
            </S.Image>
          ),
        )}
      </HorizontalScroller>
      <TextScroller>
        <S.Text dangerouslySetInnerHTML={convertMDToInnerHTML(description)} />
      </TextScroller>
    </S.HomeSection>
  );
};

HomeSection.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.object,
      }),
    ).isRequired,
  }),
  onCenterReached: PropTypes.func.isRequired,
};

HomeSection.defaultProps = {
  data: {
    description: '',
    images: [],
  },
};

export default HomeSection;
