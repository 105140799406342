import React from 'react';
import PropTypes from 'prop-types';

import { FixedImage } from 'src/modules/image';
import S from './ui.Project.Styled';

export const Project = ({ name, address, description, images }) => (
  <S.Project>
    <S.Name>{name}</S.Name>
    <S.Address>{address}</S.Address>
    <S.Description>{description}</S.Description>
    {images && (
      <S.Preview>
        {images.map(imageObj => (
          <S.ImageContainer>
            <FixedImage image={imageObj.image} imgStyle={{ width: '100%' }} />
          </S.ImageContainer>
        ))}
      </S.Preview>
    )}
  </S.Project>
);

Project.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
};

Project.defaultProps = {
  name: 'Title',
  address: 'Address',
  description: 'Description',
  images: [],
};

