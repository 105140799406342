import styled from 'styled-components';

export default {
  Project: styled.div``,
  Name: styled.div``,
  Address: styled.div``,
  Description: styled.div`
    font-size: 22px;
  `,
  Preview: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,
  ImageContainer: styled.div`
    max-width: 200px;
    padding: 5px;
  `,
};

