import styled from 'styled-components';
import { animated } from 'react-spring';
import { fontSize } from 'src/modules/text';

export default {
  IndexSection: styled.section`
    position: relative;
  `,
  Image: animated(styled.div`
    position: absolute;
    will-change: transform, height, opacity;
    height: 400px;
    padding: 0px 3px;
    flex-shrink: 0;
    overflow: hidden;
    cursor: pointer;
  `),
  NextPrev: animated(styled.div`
    background-color: white;
    position: absolute;
    will-change: transform, height, opacity;
    height: 400px;
    width: 150;
    padding: 0px 3px;
    flex-shrink: 0;
    overflow: hidden;
    cursor: pointer;
  `),
  Overlay: styled.div`
    top: 0;
    left: 3;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150;
    height: 400px;
    background-color: white;
    opacity: 0.8;
    transform: rotate(-90deg);

    @media only screen and (min-width: 600px) {
      display: none;
    }
  `,
  ImageHover: styled.div`
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    position: fixed;
    padding: 5px;
    font-size: ${fontSize.md};
    font-size: 1.2rem;
    color: white;
    mix-blend-mode: difference;
    z-index: 99;
    transform: translate3d(-50%, -50%, 0);
    white-space: nowrap;
    pointer-events: none;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  `,
  ImageContainer: styled.div``,
  Text: styled.div`
    white-space: nowrap;
  `,
};
