import React from 'react';
import PropTypes from 'prop-types';

import { colors } from 'src/styles';
import 'src/assets/fontello/css/fontello.css';
import S from './image.FontIcon.Styled';

export const FontIcon = ({ className, name, color, size, hoverable, onClick, spin }) => (
  <S.FontIcon
    className={`${className} icon-${name}`}
    color={color}
    size={size}
    hoverable={hoverable}
    onClick={onClick}
    spin={spin}
  />
);

FontIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  hoverable: PropTypes.bool,
  onClick: PropTypes.func,
  spin: PropTypes.bool,
};

FontIcon.defaultProps = {
  className: '',
  color: colors.main.black,
  size: 18,
  hoverable: false,
  onClick: () => {},
  spin: false,
};
