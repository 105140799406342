import React, { useState } from 'react';
// import PropTypes from 'prop-types';

import { Layout } from 'src/modules/layout';

import Header from './index.Header';
import IndexSection from './index.IndexSection';
import HomeSection from './index.HomeSection';
import AboutSection from './index.AboutSection';

const IndexPageTemplate = ({ homeData, aboutData, indexData }) => {
  const [section, setSection] = useState('Home');
  const items = ['Home', 'About', 'Index'];

  const updateSection = nextSection => {
    if (typeof window !== 'undefined') {
      const link = `${document.location.pathname}#${nextSection.toLowerCase()}`;
      window.history.replaceState(null, null, link);
      setSection(nextSection);
    }
  };

  return (
    <Layout>
      <Header menuItems={items} current={section} />
      <HomeSection data={homeData} onCenterReached={() => updateSection('Home')} />
      <AboutSection data={aboutData} onCenterReached={() => updateSection('About')} />
      <IndexSection data={indexData} onCenterReached={() => updateSection('Index')} />
    </Layout>
  );
};

IndexPageTemplate.propTypes = {
  homeData: HomeSection.propTypes.data.isRequired,
  aboutData: AboutSection.propTypes.data.isRequired,
  indexData: IndexSection.propTypes.data.isRequired,
};

export default IndexPageTemplate;
