import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

import { convertMDToInnerHTML } from 'src/modules/text';
import { HorizontalScroller, useCenterIntersectionObserver } from 'src/modules/layout';
import { FixedImage } from 'src/modules/image';
import { ImageFilter } from 'src/modules/input';
import { Project } from 'src/modules/ui';

import S from './index.IndexSection.Styled';

const ImageContainer = ({ imageObjs, projectType, onProjectSelected, project }) => {
  const [projectHover, setProjectHover] = useState(null);
  const poolFilter = imageObject => projectType === 'All' || imageObject.project.types.includes(projectType);

  let width = 0;
  const transitions = useTransition(
    imageObjs.filter(poolFilter).map(data => {
      const imgWidth = data.isNext || data.isPrev ? 156 : 606;
      width += imgWidth;

      return {
        ...data,
        x: width - imgWidth,
      };
    }),
    item => item.key,
    {
      from: { opacity: 0, width: 0 },
      enter: ({ x, isNext, isPrev }) => ({ x, opacity: 1, width: isNext || isPrev ? 156 : 606 }),
      leave: { opacity: 0, width: 0 },
      update: ({ x, isNext, isPrev }) => ({ x, width: isNext || isPrev ? 156 : 606 }),
    },
  );

  return (
    <S.ImageContainer>
      <ImageHover value={projectHover} />
      <HorizontalScroller itemsKey={(project && project.id) || projectType} imageContainer width={width}>
        {transitions.map(({ item, key, props: { x, ...restProps } }) => {
          if (item.isPrev || item.isNext) {
            return (
              <S.NextPrev
                key={key}
                onMouseEnter={() => setProjectHover(item.isNext ? 'next' : 'previous')}
                onMouseLeave={() => setProjectHover(null)}
                onClick={() => onProjectSelected(item.project)}
                style={{ transform: x.interpolate(value => `translate3d(${value}px,0,0)`), ...restProps }}
              >
                <S.Overlay>
                  {item.isPrev && 'previous'}
                  {item.isNext && 'next'}
                </S.Overlay>
              </S.NextPrev>
            );
          }

          return (
            <S.Image
              key={key}
              onMouseEnter={() => setProjectHover(item.project.name)}
              onMouseLeave={() => setProjectHover(null)}
              onClick={() => onProjectSelected(item.project)}
              style={{ transform: x.interpolate(value => `translate3d(${value}px,0,0)`), ...restProps }}
            >
              <FixedImage
                image={item.image}
                imgStyle={{ zIndex: -1 }}
                objectFit="cover"
                style={{ width: 600, height: 400 }}
              />
            </S.Image>
          );
        })}
      </HorizontalScroller>
    </S.ImageContainer>
  );
};

ImageContainer.propTypes = {
  imageObjs: PropTypes.arrayOf(PropTypes.object),
  projectType: PropTypes.string.isRequired,
  onProjectSelected: PropTypes.func.isRequired,
  project: PropTypes.shape({ id: PropTypes.string.isRequired }),
};

ImageContainer.defaultProps = {
  imageObjs: [],
  project: null,
};

const getWidth = ref => {
  if (!ref.current) return 0;
  const { width } = ref.current.getBoundingClientRect();
  return width;
};

const ImageHover = ({ value }) => {
  const ref = useRef();
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  useEffect(() => {
    const handleMouseMove = event => {
      const elWidth = getWidth(ref);
      const offset = elWidth / 2;
      if (event.clientX < offset) {
        setMouseX(offset);
      } else if (event.clientX > window.innerWidth - offset) {
        setMouseX(window.innerWidth - offset);
      } else {
        setMouseX(event.clientX);
      }
      setMouseY(event.clientY);
    };
    if (value) {
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [value, ref]);

  return (
    <S.ImageHover ref={ref} style={{ top: mouseY, left: mouseX }} visible={value}>
      {value}
    </S.ImageHover>
  );
};

ImageHover.propTypes = {
  value: PropTypes.string,
};

ImageHover.defaultProps = {
  value: '',
};

const IndexSection = ({ data, onCenterReached }) => {
  const [poolType, setPoolType] = useState('All');
  const [project, setProject] = useState(null);
  const { description } = data;

  const projectData = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/data/projects/" } }) {
        edges {
          node {
            excerpt(pruneLength: 250)
            id
            frontmatter {
              name
              address
              description
              type
              images {
                image {
                  childImageSharp {
                    fixed(height: 400) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  projectData.allMarkdownRemark.edges.forEach(edge => console.log(edge.node.id, edge.node.frontmatter.name));
  const projects = _.get(projectData, 'allMarkdownRemark.edges');

  const { imageObjects, projectTypes } = parseProjects(projects, project && project.id, poolType);

  const transitions = useTransition(project, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      delay: 0,
      duration: 300,
    },
  });

  useCenterIntersectionObserver(() => {
    onCenterReached();
  }, 'index');

  return (
    <S.IndexSection id="index">
      <ImageContainer
        imageObjs={imageObjects}
        project={project}
        projectType={poolType}
        onProjectSelected={proj => setProject(proj)}
      />
      {transitions.map(({ item, props }) => {
        return (
          <animated.div key={item && item.name} style={props}>
            {item && <Project name={item.name} address={item.address} description={item.description} />}
          </animated.div>
        );
      })}
      {!project && !transitions[0].item && (
        <HorizontalScroller>
          <S.Text dangerouslySetInnerHTML={convertMDToInnerHTML(description)} />
        </HorizontalScroller>
      )}
      <ImageFilter
        poolType={poolType}
        filters={['All'].concat(projectTypes)}
        onFilterClick={filter => setPoolType(filter) || setProject(null)}
      />
    </S.IndexSection>
  );
};

IndexSection.propTypes = {
  onCenterReached: PropTypes.func.isRequired,
  data: PropTypes.object,
};

IndexSection.defaultProps = {
  data: {
    description: '',
    projects: [],
  },
};

function parseProjects(edges, selectedId, poolType) {
  if (!edges) return { imageObjects: [], projectTypes: [] };

  let imageObjects = [];
  const projectTypes = new Set();

  edges.forEach((edge, index) => {
    const data = _.get(edge, 'node.frontmatter');
    if (!data) return;

    const { id } = edge.node;

    // Set default values to make sure nothing breaks
    const name = data.name || '';
    const address = data.address || '';
    const description = data.description || '';
    const type = data.type || [];
    const images = (id === selectedId ? data.images : [_.head(data.images)]) || [];

    const resultImageObjects = formatProject(name, address, description, type, images, id);
    if (!selectedId || id === selectedId) {
      if (selectedId) {
        let poolEdges;
        let poolEdgeIndex;
        if (poolType !== 'All') {
          poolEdges = edges.filter(e => _.get(e, 'node.frontmatter.type', []).includes(poolType)) || [];
          poolEdgeIndex = poolEdges.findIndex(e => e.node.id === selectedId);
        } else {
          poolEdges = edges;
          poolEdgeIndex = index;
        }

        const projects = [];

        const prevEdge = poolEdges[poolEdgeIndex - 1];
        let prevProject = _.get(prevEdge, 'node.frontmatter');
        if (prevProject) {
          prevProject = formatProject(
            prevProject.name || '',
            prevProject.address || '',
            prevProject.description || '',
            prevProject.type || [],
            prevProject.images ? [_.head(prevProject.images)] : [],
            prevEdge.node.id,
          );
          prevProject.map(p => (p.isPrev = true));
          projects.push(...prevProject);
        }

        projects.push(...resultImageObjects);

        const nextEdge = poolEdges[poolEdgeIndex + 1];
        let nextProject = _.get(nextEdge, 'node.frontmatter');
        if (nextProject) {
          nextProject = formatProject(
            nextProject.name || '',
            nextProject.address || '',
            nextProject.description || '',
            nextProject.type || [],
            nextProject.images ? [_.head(nextProject.images)] : [],
            nextEdge.node.id,
          );
          nextProject.map(p => (p.isNext = true));
          projects.push(...nextProject);
        }

        imageObjects = projects;
      } else {
        imageObjects = imageObjects.concat(resultImageObjects);
      }
    }

    // Do not save types if images do not exist
    if (resultImageObjects.length > 0) {
      type.forEach(value => projectTypes.add(value));
    }
  });

  return { imageObjects, projectTypes: Array.from(projectTypes) };
}

function formatProject(name, address, description, type, images, id) {
  const imageObjects = images
    .filter(imgObj => imgObj.image) // Make sure that image object has image field
    .map((imgObj, index) => ({
      id,
      key: `${id}-${index}`,
      image: imgObj.image,
      project: {
        id,
        name,
        address,
        description,
        types: type,
      },
    }));
  return imageObjects;
}

export default IndexSection;
